import { CIcon } from '~/icons/types'

export const ciLock: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M16.3 8.9V5.6c0-2.4-1.9-4.3-4.3-4.3S7.7 3.2 7.7 5.6V9c-2 .6-3.4 2.4-3.4 4.6V18c0 2.6 2.1 4.8 4.8 4.8h6c2.6 0 4.8-2.1 4.8-4.8v-4.5c-.1-2.2-1.6-4-3.6-4.6Zm-3.5 8.4v1.1c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-1.1c-1-.3-1.8-1.3-1.8-2.4 0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5c.1 1.1-.6 2.1-1.6 2.4Zm2-8.5H9.2V5.6c0-1.6 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8v3.2Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'lock',
  type: 'solid'
}
