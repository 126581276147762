var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CFormGroup',{staticClass:"tw-w-full",class:_vm.formGroupClass,attrs:{"label":_vm.label || _vm.$t('password'),"label-for":_vm.id,"label-class":_vm.labelClass},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("passwordLabel")]},proxy:true}],null,true)},[_vm._v(" "),_c('CInputGroup',{attrs:{"disabled":_vm.loading,"has-error":_vm.state === false},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CButton',{staticClass:"tw-w-[3.125rem] !tw-text-grey-400 !tw-ml-0 tw-border-l-0",class:{
          '!tw-text-[#f36465] tw-bg-[#fbe9e9] tw-border-[#ffd7d8]':
            _vm.showPass && _vm.state === false,
          '!tw-text-blue-400 !tw-bg-blue-50': _vm.showPass && _vm.state !== false
        },attrs:{"tertiary":"","variant":"secondary"},on:{"click":_vm.togglePassword}},[_c('CIcon',{staticClass:"tw-select-none ",attrs:{"icon":_vm.showHidePassIcon}})],1)]},proxy:true}])},[_c('CInput',{ref:"passwordInput",attrs:{"id":_vm.id,"model-value":_vm.modelValue.trim(),"autocomplete":_vm.autocomplete,"type":_vm.showPass ? 'text' : 'password',"required":_vm.required,"spellcheck":"false"},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"&keyup":function($event){return _vm.$emit('keyup', $event)}},nativeOn:{"invalid":function($event){return _vm.$emit('invalid', $event)},"input":function($event){return _vm.onInput($event)}},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('label',{staticClass:"icon lock tw-transition-colors",class:{
            active: _vm.modelValue.length,
            '!tw-text-red-400': _vm.state === false,
            '!tw-text-grey-400': _vm.state !== false,
            '!tw-text-blue-400':
              (_vm.state !== false && _vm.focused) ||
              (_vm.state !== false && _vm.modelValue.length > 0)
          },attrs:{"for":_vm.id}},[_c('CIcon',{attrs:{"icon":_vm.icons.lock,"size":"20"}})],1)]},proxy:true}])})],1),_vm._v(" "),_c('CFormError',{attrs:{"error":_vm.error,"alt":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }