import { CIcon } from '~/icons/types'

export const ciEyeSlash: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M3.5 2.5c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l18 18c.3.3.8.3 1.1 0 .3-.3.3-.8 0-1.1l-18-18ZM22.7 11.4C21.2 7 17 3.8 12 3.8c-1.5 0-2.9.3-4.2.8l2.5 2.5c.6-.2 1.1-.3 1.8-.3 2.9 0 5.2 2.4 5.2 5.2 0 .6-.1 1.2-.3 1.8l3.1 3.1c1.2-1.2 2.1-2.7 2.6-4.3.1-.4.1-.8 0-1.2Z'
    },
    {
      d:
        'M12 8.2h-.5l4.2 4.2v-.5c.1-2-1.6-3.7-3.7-3.7ZM12 15.8h.5l-4.2-4.2v.5c-.1 2 1.6 3.7 3.7 3.7Z'
    },
    {
      d:
        'M12 17.2c-2.9 0-5.2-2.4-5.2-5.2 0-.6.1-1.2.3-1.8L4 7.1c-1.2 1.2-2.1 2.7-2.6 4.3-.1.4-.1.8 0 1.1C2.8 17 7 20.2 12 20.2c1.5 0 2.9-.3 4.2-.8l-2.5-2.5c-.5.2-1.1.3-1.7.3Z'
    }
  ],
  name: 'eye-slash',
  type: 'solid'
}
