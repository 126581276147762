import { CIcon } from '~/icons/types'

export const ciEye: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d: 'M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
    },
    {
      d:
        'M22.7 11.4C21.2 7 17 3.8 12 3.8 7 3.8 2.8 7 1.3 11.4c-.1.4-.1.8 0 1.1C2.8 17 7 20.2 12 20.2c5 0 9.2-3.2 10.7-7.7.1-.3.1-.7 0-1.1ZM12 17.2c-2.9 0-5.2-2.4-5.2-5.2 0-2.8 2.3-5.2 5.2-5.2 2.9 0 5.2 2.4 5.2 5.2 0 2.8-2.3 5.2-5.2 5.2Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  name: 'eye',
  type: 'solid'
}
